<template>
  <div class="banner overflow-hidden w-full">
    <div class="banner-card flex flex-col-reverse items-center justify-center py-6 px-6 lg:py-12 lg:px-20 lg:flex-row">
      <div class="lg:flex-1 flex flex-col items-center lg:items-start justify-center space-y-3 lg:space-y-6">
        <h1 class="text-primary text-3xl lg:text-5xl font-bold">
          {{ settings.header }}
        </h1>
        <p>
          {{ settings.subHeader }}
        </p>
      </div>
      <div class="flex-1 text-base lg:text-lg">
        <img :src="settings.image" class="block h-auto mx-auto mb-8 lg:mb-0" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      required: false,
      default: () => ({
        header: "",
        subHeader: "",
        image: "",
      })
    },
  },
};
</script>

<style lang="scss">
.banner {
  &-card {
    background-color: #cff1e9;
    border-radius: 1.8rem;
  }

  img {
    width: 85% !important;

    & {
      @media screen and (min-width: 992px) {
        width: 30rem !important;
      }
    }
  }
}
</style>
