<template>
  <div class="links w-full">
    <h5 class="text-xl lg:text-2xl font-semibold">{{this.settings.popular_category_text}}</h5>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
      <div
        v-for="(category, i) in categories"
        :key="i"
        class="links-container flex flex-row items-start items-center justify-start space-x-4 border border-solid rounded-lg bg-transparent py-4 px-5 lg:py-3 lg:px-4 font-semibold text-left text-lg lg:text-xl">
        <img class="h-auto max-w-full" :src="category.icon"/>

        <div class="flex flex-col">
          {{ category.label }}
          <button
            @click="goTo(category.value)"
            class="mt-4 links-learn-more flex-shrink-0 vs-button vs-button-border vs-button-primary bg-white border border-solid text-primary font-medium">
            Learn more
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    goTo(topic) {
      const userType = this.$route.path.split('/')[1]

      this.$router.push({
        name: `${userType}-topic-resources`,
        params: { topic }
      });
    }
  },
  computed: {
    categories() {
      return this.$store.state.resourceHub.filters.categories;
    },
    settings() {
      return this.$store.state.resourceHub.settings;
    }
  }
};
</script>

<style lang="scss">
.links {
  &-container {
    border-color: #e3ddd7;
    font-family: inherit;

    img {
      border-radius: 8px;
    }
  }

  &-learn-more {
    max-width: 150px;
    color: #0E4333;
    background: rgba(255, 255, 255, 1) !important;
    border: 1px solid rgba(227, 221, 215, 1);
  }
}
</style>
