<template>
  <div class="flex">
    <vs-button
      @click="slide('prev')"
      class="mr-2 arrow-btn"
      color="primary"
      type="border"
      icon="arrow_back"></vs-button>
    <vs-button
      @click="slide('next')"
      color="primary"
      class="arrow-btn"
      type="border"
      icon="arrow_forward"></vs-button>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
  methods: {
    slide(to) {
      this.$emit("slide", to)
    }
  },
}
</script>

<style lang="scss">
.arrow-btn {
  border: 1px solid rgba(227, 221, 215, 1);
  background: rgba(255, 255, 255, 1);

  .vs-icon {
    font-size: 1.5rem;
  }
}
</style>
