<template>
  <div class="nurseResources w-full">
    <!-- navigation -->
    <vs-breadcrumb
      class="block mb-2 resources-breadcrumb"
      :items="populateBreadcrumbs"
    ></vs-breadcrumb>

    <div class="nurseResources-wrapper flex flex-col items-center space-y-6">
      <!-- banner -->
      <Banner :settings="settings"></Banner>

      <!-- filter -->
      <Filters></Filters>

      <!-- categories -->
      <Categories></Categories>

      <!-- divider -->
      <vs-divider  />

      <!-- focus -->
      <Focus></Focus>

      <!-- divider -->
      <vs-divider />

      <!-- recent -->
      <Recent></Recent>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Banner from "./resources/Banner.vue";
import Focus from "./resources/Focus.vue";
import Recent from "./resources/Recent.vue";
import Categories from "./resources/Categories.vue";
import Filters from "./resources/Filters.vue";

export default {
  components: {
    Banner,
    Focus,
    Recent,
    Categories,
    Filters,
  },
  methods: {
    ...mapActions("resourceHub", ["fetchAllFilters", "fetchRecentResources", "fetchComplianceResources"]),
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapMutations("resourceHub", ["MUTATE_RESOURCE_JSON_SETTING"]),

    async init() {
      try {
        this.$vs.loading();

        const { data } = await this.getSettingsByKey("resourceHubSetting")
        let formattedValue = {
          main_banner: {
            header: "",
            subHeader: "",
            image: ""
          },
          first_section: {
            header: "",
            tag: ""
          },
        }

        if (Object.keys(JSON.parse(data.data.value)).length) {
          formattedValue = JSON.parse(data.data.value)
        }

        this.MUTATE_RESOURCE_JSON_SETTING(formattedValue)
        await this.fetchAllFilters();
        await this.fetchRecentResources()
        await this.fetchComplianceResources(formattedValue.first_section.tag)
      } catch (error) {
        console.log(`Error fetching all filters: ${error}`)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  computed: {
    populateBreadcrumbs () {
      return [
        { title: 'Resources', url: "#" },
        {
          title: "Home",
          active: true,
        },
      ]
    },
    settings() {
      const { main_banner } = this.$store.state.resourceHub.settings;

      return {
        header: main_banner.header,
        subHeader: main_banner.subHeader,
        image: main_banner.image,
      }
    }
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.resources-breadcrumb {
  .vs-breadcrumb--link {
    color: rgba(14, 67, 51, 1);
  }
  .vs-active {
    .vs-breadcrumb--text {
      color: rgba(37, 48, 44, 0.65);
    }
  }
}

.nurseResources {
  .vs {
    &-divider {
      margin: 0;
    }
  }
}
</style>
