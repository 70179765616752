<template>
  <div class="recent w-full">
    <Card 
      :slide="slideTo" 
      display-type="carousel" 
      :resources="resources" 
      :title="this.settings.recent_resources_text" 
    />
  </div>
</template>

<script>
import Card from "./Card.vue";
import ArrowButtons from "./ArrowButtons.vue";
import Filters from "./Filters.vue";

export default {
  components: {
    Card,
    ArrowButtons,
    Filters,
  },
  data() {
    return {
      slideTo: null
    };
  },
  computed: {
    resources() {
      // return this.$store.state.resourceHub.resources.recent.results.map((i) => ({ ...i, isRecent: true }))

      return this.$store.state.resourceHub.resources.recent.results
        .map((i) => ({ ...i, isRecent: true }))
        .filter(item => 
          item.data &&
          item.data.brand != null && item.data.brand !== undefined && item.data.brand.data && 
          item.data.category != null && item.data.category !== undefined && item.data.category.type &&
          item.data.resource_type != null && item.data.resource_type !== undefined && item.data.resource_type.type &&
          item.data.title != null && item.data.title !== undefined && item.data.title.length > 0
        )
    },
    settings() {
      return this.$store.state.resourceHub.settings;
    }
  }
};
</script>
