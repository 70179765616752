<template>
  <div class="focus w-full">
    <Card 
      display-type="carousel"
      :resources="resources"
      :carousel-options="carouselOptions"
      :isFromFirstSection="true"
      :title="settings.first_section.header"
    />
  </div>
</template>

<script>
import Card from "./Card.vue"
import ArrowButtons from "./ArrowButtons.vue"

export default {
  components: {
    Card,
    ArrowButtons,
  },
  data() {
    return {
      carouselOptions: {
        slidesPerView: 2,
      }
    }
  },
  computed: {
    resources() {
      return this.$store.state.resourceHub.resources.compliance.results;
    },
    settings() {
      return this.$store.state.resourceHub.settings;
    }
  }
};
</script>
